import {VariableDataElementAbstract} from './VariableDataElementAbstract';
import {Field, field} from '../../../../core/models/fields/Field';
import {SymbolType} from '../enums';
import {zip} from '../../../../core/utils/Arrays';

export default abstract class VariableDataObjectElementAbstract extends VariableDataElementAbstract {

    @field(Field)
    variable_data: any[];

    variable_data_editable = false;

    defaultRow(){
        return {};
    }

    private _last_range_key;
    numberRangeKey() {
        const rangeRegex: RegExp = /[0-9]+/g;

        if (this.variable_data.length == 0) {
            return null;
        }

        let start = this.variable_data[0];
        let end = this.variable_data[1];
        let keys = Object.keys(start);

        if (keys.length == 1) {
            this._last_range_key = keys[0];
            return keys[0];
        }

        for (let key of keys) {
            if (start[key] != end[key]) {
                if (start[key].match(rangeRegex)?.length > 0) {
                    this._last_range_key = key;
                    return key;
                }
            }
        }

        // In case the user is editing the key and its no longer a number
        // we don't want to change the key on them.
        if (this._last_range_key) {
            return this._last_range_key;
        }

        return keys.length > 0 ? keys[0] : null;
    }

    get symbol_range_start(): string {
        let range_key = this.numberRangeKey();
        if (!range_key) {
            return null;
        }

        return this.formatValue(this.variable_data[0][range_key]);
    }

    set symbol_range_start(v: string) {
        let range_key = this.numberRangeKey();
        if (!range_key) {
            return;
        }

        this.variable_data[0][range_key] = this.formatValue(v);
    }

    get symbol_range_end(): string{
        let range_key = this.numberRangeKey();
        if (!range_key) {
            return null;
        }

        return this.formatValue(this.variable_data[1][range_key]);
    }

    set symbol_range_end(v: string) {
        let range_key = this.numberRangeKey();
        if (!range_key) {
            return;
        }

        this.variable_data[1][range_key] = this.formatValue(v);
    }

    formatValue(value) {
        if (!value) {
            return value;
        }
        if (this.maxDataLength() && typeof value === 'string') {
            return value.slice(0, this.maxDataLength())
        }
        return value;
    }

    get variable_data_start() {
        if (!this.variable_data || this.variable_data.length != 2) {
            this.variable_data = [Object.create(null), Object.create(null)];
        }
        return this.variable_data[0];
    }

    get variable_data_end() {
        if (!this.variable_data || this.variable_data.length != 2) {
            this.variable_data = [Object.create(null), Object.create(null)];
        }
        return this.variable_data[1];
    }

    get range(): number|null {
        if (this.symbol == SymbolType.CSV_LIST) {
            if (!this.variable_data) {
                return null;
            }

            return this.variable_data.length;
        }

        if (this.symbol == SymbolType.NUMBER_RANGE) {
            while (this.variable_data.length < 2) {
                this.variable_data.push({...this.defaultRow()});
            }

            let range_key = this.numberRangeKey();
            if (!range_key) {
                return;
            }

            let quantity = 1;
            const rangeRegex: RegExp = /[0-9]+/g;
            let start = this.formatValue(this.variable_data[0][range_key]);
            let end = this.formatValue(this.variable_data[1][range_key]);

            const startNumbers: string[] = start?.match(rangeRegex);
            const endNumbers: string[] = end?.match(rangeRegex);

            if (startNumbers === null || endNumbers === null) {
                return null;
            }

            for (const set of zip(startNumbers, endNumbers)) {
                const q: number = Math.abs(parseInt(set[1], 10) - parseInt(set[0], 10)) + 1;
                if (q > quantity)
                    quantity = q;
            }

            return quantity;
        }

        return null;
    }

    removeBlankRows() {
        if (this.variable_data) {
            this.variable_data = this.variable_data.reduce((reduced, item) => {
                for (const key of Object.keys(item)) {
                    if (item[key] && item[key].trim() != '') {
                        reduced.push(item);
                        return reduced;
                    }
                }
                return reduced;
            }, []);
        }
    }

    asCSVElement() {
        let csv_data = {}
        for (const line of this.variable_data) {
            for (const key of Object.keys(line)) {
                if (!csv_data[key]) {
                    csv_data[key] = []
                }
                csv_data[key].push(line[key]);
            }
        }

        for (const key of Object.keys(csv_data)) {
            csv_data[key] = csv_data[key].join(',');
        }

        return csv_data;
    }

    fromCSVElement(csv_element) {
        this.variable_data = [];

        let max_length = 0;
        let split_data = {};
        for (const key of Object.keys(csv_element)) {
            split_data[key] = csv_element[key].split(',');
            if (split_data[key].length > max_length) {
                max_length = split_data[key].length;
            }
        }

        for (let i = 0; i < max_length; i++) {
            this.variable_data.push(Object.create(null));
        }

        for (const key of Object.keys(split_data)) {
            for (const index in split_data[key]) {
                this.variable_data[index][key] = split_data[key][index];
            }
        }
    }

    maxDataLength() {
        return null;
    }
}
