import {field} from '../../../../core/models/fields/Field';
import {FloatField} from '../../../../core/models/fields/FloatField';
import {PositiveIntegerField} from '../../../../core/models/fields/PositiveNumberField';
import {Model} from '../../../../core/models/Model';
import {Color} from '../Color';
import {CustomLamination, ProductTypeEnum} from '../enums';
import {IConfiguredProduct} from '../IConfiguredProduct';
import {IProduct} from '../IProduct';
import {Material} from '../Material';
import {ForeignKeyField} from '../../../../core/models/fields/ForeignKeyField';
import {Services} from '../../../../core/services/Services';
import {Http} from '../../../../core/services/Http';
import {IPromise, SimplePromise} from '../../../../core/utils/SimplePromise';
import ConfiguredRetailPackage from '../../../packaging/models/ConfiguredRetailPackage';
import {Price} from '../../../../core/utils/Price';
import {Collection} from '../../../../core/models/Collection';
import AbstractProductOption from '../AbstractProductOption';
import {TemplateOption, TemplateOptionChargeOption} from '../TemplateOption';
import {TemplateOptionSet} from '../TemplateOptionSet';

export enum QuantityType {
    SINGLE_VALUE,
    RANGE
}

export class ConfiguredProductAbstract extends Model implements IConfiguredProduct {
    productClass: string;

    @field(ForeignKeyField, {
        model: 'Material',
        readOnly: true
    })
    material: Material;

    @field(ForeignKeyField, {
        model: 'Color',
        readOnly: true
    })
    material_color: Color;

    @field()
    option_laminate: CustomLamination;

    @field()
    option_reverse_cut: boolean;

    @field()
    option_flex_cut: boolean;

    @field()
    type: ProductTypeEnum; // Product type, Printed, cut-out, etc.

    @field()
    currency: string;

    @field(FloatField, {
        toFixed: 3,
        required: true
    })
    width: number;

    @field(FloatField, {
        toFixed: 3,
        required: true
    })
    height: number;

    @field(PositiveIntegerField, {
        required: true,
        min: 1
    })
    quantity: number;

    /*  Quantity defined by some other means
        A flag to disable or change the way the quantity should be handled
     */
    @field()
    defined_quantity: boolean;

    @field()
    variable_data: boolean;

    @field(ForeignKeyField, {
        model: 'ConfiguredRetailPackage',
        readOnly: true
    })
    retail_package: ConfiguredRetailPackage;

    options: Collection<AbstractProductOption>;

    /*
        Used to determine how the quantity is calculated
     */
    get quantity_type() {
        return QuantityType.SINGLE_VALUE;
    }

    get product(): IProduct {
        return null;
    }

    set product(p: IProduct) {}

    addToCart(): IPromise<any> {
        if (!this.id) {
            let deferred = SimplePromise.defer<Model>();
            deferred.reject('Unable to add the product to the cart, please refresh and try again.');
            return deferred.promise;
        }

        const $http = Services.get<Http>('$http');

        const url = new URL(window.location.href);

        return $http.request({
            url: '/custom/api/v1/add-to-cart/',
            method: 'POST',
            data: {
                product_class: this.productClass,
                product_id: this.id,
                quantity: this.quantity,
                search: url.searchParams.get('q')
            }
        });
    }

    getNextURL(): string {
        // Default is cart page.
        return '/cart/';
    }

    get sqInches(): number {
        if (this.width == null || this.height == null)
            return 0;
        return this.width * this.height;
    }

    override validate() {
        super.validate();

        // Size/Material validation
        if (this.product && this.product.materials && this.product.materials.length > 0) {
            let skip_material_check = this.material_color && this.material_color.sizeIsRestricted();
            if (!skip_material_check) {
                const material = this.product.materials.getItemFromURI(this.material);
                if (material && (!material.sizeIsValid(this.cleaned.width, this.cleaned.height) || !material.quantityIsValid(this.quantity))) {
                    this._errors.merge(material.errors.list);
                }
            }
            else {
                if (!this.material_color.sizeIsValid(this.cleaned.width, this.cleaned.height)) {
                    this._errors.merge(this.material_color.errors.list);
                }
            }
        }

        // Material color on cut-out stickers
        if (this.product && this.product.type === ProductTypeEnum.CUTOUT && (this.material_color == null || !(this.material_color as Color).id)) {
            this._errors.add('material_color', 'Please select a color.');
        }

        if (this.product.remaining_stock != null) {
            if (this.quantity > this.product.remaining_stock) {
                if (!this.product.allow_backorders) {
                    if (this.product.remaining_stock == 0) {
                        this.errors.add('quantity', 'The product is out of stock.');
                    }
                    else {
                        this.errors.add('quantity', `Only ${this.product.remaining_stock} products are left in stock.`);
                    }
                }
            }
        }

        if (this.width <= 0) {
            this.errors.add('width', 'The width must be greater than 0.')
        }
        if (this.height <= 0) {
            this.errors.add('height', 'The height must be greater than 0.')
        }


        return this._errors;
    }

    public calcOptionsPrice(price, show_retail?: boolean) {
        let subTotal : Price = Price.zero;

        if (this.retail_package && this.retail_package.package.getValidSizeFor(this.width, this.height)) {
            subTotal = subTotal.add(this.retail_package.priceFor(this.quantity).price);
        }

        if (this.options) {
            for (const option of this.options) {
                // Make sure the data is loaded
                if (!option || !option.$resolved) {
                    continue;
                }

                if (option.isDisabled(this.material)) {
                    continue;
                }

                subTotal = subTotal.add(this.getOptionValue(option.option, option.option_set , price, show_retail));
            }
        }

        return subTotal.value;
    }

    getOptionValue(option: TemplateOption, option_set: TemplateOptionSet, price: number, show_retail?: boolean): number {
        if (!option_set || !option) {
            return 0;
        }

        if (!option_set.optionEligible(this.width, this.height)) {
            return 0;
        }

        if (option.charge_option === TemplateOptionChargeOption.CHARGE_TOTAL) {
            return Number(option.display_value(show_retail, option_set.quantityBreakIndex(this.quantity)));
        }
        else if (option.charge_option === TemplateOptionChargeOption.CHARGE_UNIT) {
            return Number(option.display_value(show_retail, option_set.quantityBreakIndex(this.quantity))) * this.quantity;
        }
        else if (option.charge_option === TemplateOptionChargeOption.CHARGE_PERCENTAGE) {
            // Need to round to the nearest penny on the unit price
            let unit_price = price / this.quantity;
            let percentage = Number(option.display_value(false, option_set.quantityBreakIndex(this.quantity))) / 100;
            let percentage_amount =  percentage * unit_price;
            let percentage_amount_rounded = Number(percentage_amount.toFixed(2));

            return percentage_amount_rounded * this.quantity;
        }

        return 0;
    }

    public laminationOptions = [
        {
            label: 'Gloss Lamination',
            id: CustomLamination.GLOSS
        },
        {
            label: 'Matte Lamination',
            id: CustomLamination.MATTE
        }
    ];

    public borderOptions = [
        {
            label: 'No Thanks',
            id: false
        },
        {
            label: 'Add Border',
            id: true
        }
    ];

    laminationName() {
        if (this.option_laminate == CustomLamination.GLOSS) {
            return 'Gloss Lamination';
        }
        else if (this.option_laminate == CustomLamination.MATTE) {
            return 'Matte Lamination';
        }
    }
}
