import {field} from '../../../../core/models/fields/Field';
import {SymbolType} from '../enums';
import {zip} from '../../../../core/utils/Arrays';
import {VariableDataElementAbstract} from './VariableDataElementAbstract';

export abstract class VariableDataTextElementAbstract extends VariableDataElementAbstract {
    @field()
    symbol_name: string;

    @field()
    symbol_range_start: string;

    @field()
    symbol_range_end: string;

    @field()
    text: string;

    variable_data_editable = true;

    _range_from_primary;
    setRangeFromAsPrimary() {
        this._range_from_primary = true;
    }

    multipleRangeSegments() {
        const rangeRegex: RegExp = /[0-9]+/g;
        if (!this.symbol_range_start || !this.symbol_range_end) {
            return false;
        }

        let left = this.symbol_range_start.match(rangeRegex);
        let right = this.symbol_range_end.match(rangeRegex);

        if (!left || !right) {
            return false;
        }

        return left.length > 1 || right.length > 1;
    }

    rangeStartInvalid() {
        if (this.symbol != SymbolType.NUMBER_RANGE) {
            return false;
        }
        if (!this.symbol_range_start) {
            return true;
        }
        const rangeRegex: RegExp = /[0-9]+/g;

        let result = this.symbol_range_start.match(rangeRegex);
        return !result || result.length != 1;
    }

    rangeEndInvalid() {
        if (this.symbol != SymbolType.NUMBER_RANGE) {
            return false;
        }
        if (!this.symbol_range_end) {
            return true;
        }
        const rangeRegex: RegExp = /[0-9]+/g;

        let result = this.symbol_range_end.match(rangeRegex);
        return !result || result.length != 1;
    }

    get range(): number|null {
        if (this.symbol == SymbolType.NUMBER_RANGE) {
            let quantity: number = 1;
            const rangeRegex: RegExp = /[0-9]+/g;
            const startNumbers: string[] = this.symbol_range_start ? this.symbol_range_start.match(rangeRegex) : null;
            const endNumbers: string[] = this.symbol_range_end ? this.symbol_range_end.match(rangeRegex) : null;

            if (startNumbers === null || endNumbers === null)
                return null;

            for (const set of zip(startNumbers, endNumbers)) {
                const q: number = Math.abs(parseInt(set[1], 10) - parseInt(set[0], 10)) + 1;
                if (q > quantity)
                    quantity = q;
            }

            return quantity;
        }

        if (this.symbol == SymbolType.CSV_LIST) {
            return this.formatted_csv.length;
        }

        return null;
    }

    get formatted_csv() {
        if (!this.text || this.text == '') {
            return [''];
        }

        return this.text.split(',');
    }

    get textDisplay(): string {
        switch (this.symbol) {
            case SymbolType.NUMBER_RANGE:
                if (this._range_from_primary) {
                    return this.symbol_range_end;
                }
                return this.symbol_range_start;
            case SymbolType.VARIABLE_DATA:
                return this.symbol_name;
            case SymbolType.CSV_LIST:
                return this.formatted_csv[0];
            default:
                return this.text;
        }
    }

    override onSymbolChange(old_value: SymbolType, new_value: SymbolType) {
        super.onSymbolChange(old_value, new_value);
        if (new_value == SymbolType.CSV_LIST) {
            if (this.symbol_range_start) {
                this.text = this.symbol_range_start;
            }

            this.symbol_range_start = '';
            this.symbol_range_end = '';
        }
        if (new_value == SymbolType.NUMBER_RANGE) {
            this.symbol_range_start = this.text;
            this.symbol_range_end = '';
            this.text = 'Number Range';
        }
    }

    isVariableData() {
        return !!this.symbol;
    }
}
