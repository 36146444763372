import {field} from '../../../core/models/fields/Field';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Model} from '../../../core/models/Model';
import {Collection} from '../../../core/models/Collection';
import {ToManyURIField, TToManyKeyURI} from '../../../core/models/fields/ToManyURIField';
import {Material} from './Material';
import {TemplateOptionTag} from './TemplateOptionTag';
import {TemplateOption} from './TemplateOption';
import {APIResource} from '../../../core/models/APIResource';
import {DisplayTypeEnum} from './enums';

export class TemplateOptionSet extends Model {
    static override uri:string = '/custom/api/v1/template-option-set/:id/';
    static override objects: APIResource<TemplateOptionSet>;

    @field()
    display_type: DisplayTypeEnum;
    
    @field()
    help_text: string;
    
    @field()
    name: string;

    @field()
    short_description: string;
    
    @field(ToManyField, {
        model:'TemplateOption'
    })
    options: Collection<TemplateOption>;

    @field(ToManyURIField, {
        model: 'Material'
    })
    excluded_materials: TToManyKeyURI<Material>;

    @field(ToManyField, {
        model: 'TemplateOptionTag'
    })
    tags: Collection<TemplateOptionTag>;

    @field()
    minimum_width: number;

    @field()
    minimum_height: number;

    @field()
    maximum_width: number;

    @field()
    maximum_height: number;

    @field()
    quantity_breaks: string;

    @field()
    small_option: boolean;

    @field()
    required: boolean;

    public getTaggedOption(slug) {
        for (const option of this.options) {
            if (option.hasTag(slug)) {
                return option;
            }
        }

        return null;
    }

    public optionEligible(width, height) {
        if (this.minimum_height && height < this.minimum_height) {
            return false;
        }
        if (this.minimum_width && width < this.minimum_width) {
            return false;
        }
        if (this.maximum_height && height > this.maximum_height) {
            return false;
        }
        if (this.maximum_width && width > this.maximum_width) {
            return false;
        }

        return true;
    }

    public quantityBreaks(): number[] {
        if (!this.quantity_breaks) {
            return [];
        }

        return this.quantity_breaks.split(',').map(v => Number(v));
    }

    public quantityBreakIndex(quantity: number): number {
        let breaks = this.quantityBreaks();

        if (breaks.length == 0) {
            return null;
        }

        let index = 0;
        for (let i = 0; i < breaks.length; i++) {
            let value = breaks[i];

            if (value <= quantity) {
                index = i+1;
            }
        }

        return index;
    }

    validateDisplayType() {
        /*
            The template option set can be configured to display options in many different ways.
            However, not all of these make sense. For instance the toggle option is required to have
            exactly two options: true and false. But the option set supports between 0 and N number of
            options where none or multiple of the options may match up to true or false. The dropdown
            display type could be selected, but only one item in the dropdown could be available which
            isn't a choice, it's a fixed value. So we need to prevent some option set types from rendering
            out as requested when they are unable to do and would break the page.
        */
        if (this.display_type == DisplayTypeEnum.TOGGLE_BUTTON) {
            if (this.options.length != 2 || !this.options.find(v => v.option_value == 'true') || !this.options.find(v => v.option_value == 'false')) {
                this.display_type = DisplayTypeEnum.DROPDOWN;
            }
        }

        // Idk why this option exists, it has never been supported.
        if (this.display_type == DisplayTypeEnum.CHECKBOX) {
            this.display_type = DisplayTypeEnum.RADIO;
        }

        if (this.display_type == DisplayTypeEnum.RADIO && this.options.length > 6) {
            this.display_type = DisplayTypeEnum.DROPDOWN;
        }

        if (this.options.length == 1) {
            this.display_type = DisplayTypeEnum.FIXED_VALUE;
        }
    }
}
